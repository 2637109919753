import { tw } from '@/utils/tw';
import BasePaginationTheme from 'base/components/Pagination/theme';

const Icon = tw.theme({
  extend: BasePaginationTheme.Icon,
  base: `bg-linen-300 [&_path]:fill-black`,
});

const Pagination = tw.theme({
  extend: BasePaginationTheme,
  slots: {
    button: `text-content-sm sm:text-content-sm data-[active=true]:bg-black data-[active=true]:text-white`,
    dots: `text-content-sm sm:text-content-sm`,
  },
});

export default Object.assign(Pagination, { Icon });
